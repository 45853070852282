import React from "react"
import { Container, Carousel, Button} from 'react-bootstrap'
import HF from "../images/HF.jpg"
import HERO from "../images/Hero.jpg"


import { StaticQuery, graphql, Link } from "gatsby"

const HealthFitness = () => {

    return (
        <div>
        <StaticQuery
        query={graphql`
          query HealthFitness {
            allStrapiHealthFitness( limit: 3, sort: {fields: date, order: DESC}) {
                edges {
                node {
                    title
                    slug
                    id
                    date(formatString: "LL")
                    imgix_path
                    src_img
                }
                }
            }
          }
        `}
        render={data => (
            <React.Fragment>
             {/* <div className=" py-10 px-4 ">
            <div className="grid  grid-cols-1  mt-20">
            <div className="">
                 <p className="font-playfairdisplay font-bold text-6xl md:text-7xl text-center  pb-10 lg:mt-12 xl:mt-0">Health & Fitness</p>
             </div>
            {data.allStrapiHealthFitness.edges.map(hf =>(
            <div key={hf.node.id} className="md:mb-10">
             <Link to={`/health_fitness/${hf.node.slug}`}>
             <div className="mt-8">
               <img
               srcSet={hf.node.imgix_path} 
               src={hf.node.src_img} alt="Health & Fitness"  
               className="w-full sm:w-3/4 sm:ml-1/8 h-80 object-cover"/>
               <p className="font-montserrat font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl mt-4 text-center">{hf.node.title}</p>
               <p className="font-opensans font-bold mt-2 text-center">{hf.node.date}</p>
             </div>
             </Link>
            </div>
            ))}
            </div>
            </div> */}
            <div style={{backgroundImage: `url(${HF})`, backgroundSize: "cover"}} className="xs:pt-16 sm:pt-16 sm:pb-16 lg:pt-24 xl:pt-32 2xl:pt-64">
            <Container className="xs:h-112 sm:h-96 lg:h-100 xl:h-104 2xl:h-116">
              <div className="flex flex-col sm:flex-row justify-between h-72 md:h-96">
              <div className=" py-5 px-3 md:p-5 bg-white opacity-75">
                <h1 className="font-bold text-3xl md:text-5xl lg:text-7xl text-blue-dark "> Health & Fitness</h1>
                <p className="text-blue-dark text-xs md:text-sm lg:text-base md:mb-10">The Health Message is the right arm of the Gospel, but what does that really mean? Are we living it or are we just talking about it. We need to get serious. Now is the time for Action!</p>
                <Link to="/health_fitness">
                <Button>It's about Practical Living</Button>
                </Link>
              </div>
              <div className="w-full">
              <Carousel>
              {data.allStrapiHealthFitness.edges.map(hf =>(
                  <Carousel.Item interval={5000} key={hf.node.id}>
                  <Link to={`/health_fitness/${hf.node.slug}`}>
                    <img
                      className="d-block w-100 h-72 md:h-96 object-cover"
                      srcSet={hf.node.imgix_path} 
                      src={hf.node.src_img} alt="Health & Fitness"
                      alt="Health & Fitness"
                    />
                    <Carousel.Caption>
                      <h3>{hf.node.title}</h3>
                      <p>{hf.node.date}</p>
                    </Carousel.Caption>
                    </Link>
                  </Carousel.Item>
                  ))}
                </Carousel>
                </div>
                </div>
            </Container>
            </div>
             </React.Fragment>
          )}
         />
     </div>
    )
}
export default HealthFitness
