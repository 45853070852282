import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { Container, Image, Row, Col, Button } from "react-bootstrap"
import BG from "../images/bgnd2.png"

const BibleStudy = () => {
  return (
    <div>
      <StaticQuery
        query={graphql`
          query BibleStudies {
            allStrapiBiblestudies(
              limit: 4
              sort: { fields: date, order: DESC }
            ) {
              edges {
                node {
                  word
                  title
                  slug
                  category
                  question
                  prophecy
                  date(formatString: "LL")
                  description
                  featured
                  home
                  id
                  channel
                  author {
                    name
                    avatar {
                      formats {
                        thumbnail {
                          url
                        }
                      }
                    }
                  }
                  src_image
                  imgix_path
                }
              }
            }
          }
        `}
        render={data => (
          <React.Fragment>
            {/*  <div className=" xs:py-20 sm:py-32 xs:px-4 lg:px-0">
                    <p className="font-playfairdisplay font-bold xs:text-8xl sm:text-9xl text-center text-gray-700 ml-4 mb-8">Bible Study</p>
                    <div className="border-b-8 border-orange-dark border-double mb-8 "></div>
                       <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 lg:grid-cols-4 lg:gap-0">
                       
                            {data.allStrapiBiblestudies.edges.map(bible =>(
                                <Link to={`/biblestudy/${bible.node.category}/${bible.node.slug}`}>
                                        <div key={bible.node.id} >
                                        <div className="relative">
                                            
                                            <div className="absolute z-10 h-full xl:opacity-0 xl:hover:opacity-100 text-white transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-90 ">
                                            <p className="font-montserrat font-bold md:text-xl  xl:text-2xl mt-8 xl:mt-2 xs:mx-2 xl:mx-0">{bible.node.title}</p>
                                            <p className="font-opensans text-sm mt-1 xs:mx-2 xl:mx-0">{bible.node.author.name}, {bible.node.date}</p>
                                            <p className="font-opensans md:text-sm xl:text-base mt-2 xs:mx-2 xl:mx-0 hidden xl:block">{bible.node.description}</p>
                                            
                                            <p className="font-opensans font-bold text-orange-dark text-xs mt-2 xs:mx-2 xl:mx-0"> Read More</p>
                                          
                                            </div>
                                            <img src={bible.node.imagehomepage.url} alt="Hero" className="w-full h-48 lg:h-64 xl:h-96 object-cover" />
                                            </div>
                                        </div>
                                        </Link>
                                ))}
                             
                       </div>
                      
                    </div> */}
            <div
              style={{ backgroundImage: `url(${BG})`, backgroundSize: "cover" }}
              className="bg-blue-dark1 pb-32"
            >
              <Container>
                <div className="flex flex-row  items-center text-orange-light pt-24 pb-2">
                  <i className="fas fa-bible fa-lg pr-2"></i>
                  <p className="m-0 font-opensans">The Word</p>
                </div>
                <div className="sm:flex flex-row justify-between items-center pb-4">
                  <h1 className="text-white">The Latest Bible Studies</h1>
                  <Link to="/biblestudy">
                    <Button>All Bible Studies</Button>
                  </Link>
                </div>
                <Row className="">
                  {data.allStrapiBiblestudies.edges.map(bible => (
                    <Col
                      key={bible.node.id}
                      sm={6}
                      lg={4}
                      xl={3}
                      className="mb-4"
                    >
                      <div className="bg-white shadow-md rounded-b-md p-3">
                        <div className="absolute z-10 mt-20 bg-white rounded-r-lg">
                          <p className="xs:w-56 md:w-64 pr-2 text-sm">
                            {bible.node.description}
                          </p>
                        </div>
                        <div>
                          <Image
                            srcSet={bible.node.imgix_path}
                            src={bible.node.src_image}
                            alt="Hero"
                            className="h-96 object-cover"
                          />
                        </div>
                        <div className="flex flex-row items-center">
                          <i className="far fa-calendar-alt  pr-4 my-4 text-green-500"></i>
                          <p className="m-0 font-opensans font-medium text-sm text-gray-700">
                            {bible.node.date}
                          </p>
                        </div>
                        <div className="flex flex-row items-center">
                          <i class="far fa-newspaper pr-2 text-pink-800"></i>
                          <p className="m-0 font-bold font-opensans text-gray-700">
                            {bible.node.title}
                          </p>
                        </div>
                        <div className="mx-4">
                          <hr />
                        </div>
                        <Link
                          to={`/biblestudy/${bible.node.category}/${bible.node.slug}`}
                        >
                          <div className="flex flex-row items-center my-4">
                            <p className="m-0 font-montserrat font-bold text-lg text-gray-700">
                              Go to Bible Study
                            </p>
                            <i className="fas fa-chevron-right  pl-4 text-orange-alt"></i>
                          </div>
                        </Link>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </div>
          </React.Fragment>
        )}
      />
    </div>
  )
}
export default BibleStudy
