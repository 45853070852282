import React from "react"
import { Container, Image, Row, Col } from "react-bootstrap"
import BG from "../images/bgnd4.png"

import { StaticQuery, graphql, Link } from "gatsby"

const Diet = () => {
  return (
    <div>
      <StaticQuery
        query={graphql`
          query Recipes {
            allStrapiRecipes(limit: 8, sort: { fields: date, order: DESC }) {
              edges {
                node {
                  title
                  slug
                  category
                  featured
                  calories
                  serving
                  ingredients
                  chef {
                    id
                    name
                    image {
                      formats {
                        thumbnail {
                          url
                        }
                      }
                    }
                  }
                  date(formatString: "LL")
                  duration
                  imgix_path
                  src_img
                }
              }
            }
          }
        `}
        render={data => (
          <React.Fragment>
            {/* <div className="sm:w-3/4 sm:ml-1/8 py-20 xs:px-4 sm:px-0">
            <div>
                <p className="font-playfairdisplay font-bold text-7xl text-white mb-10 text-center">ReaCh HD</p>
            </div>
            <div className="grid grid-cols-1  lg:grid-cols-2  gap-8">
              {data.allStrapiRecipes.edges.map(diet =>(
                  <div key={diet.node.id} className="text-white sm:mb-24 md:mb-48 lg:mb-32 xl:mb-64  sm:h-108 md:h-100">
                  <Link to={`/diet/${diet.node.category}/${diet.node.slug}`}>
                        <img 
                        srcSet={diet.node.imgix_path}
                        src={diet.node.src_img} alt="Food" 
                        className=" w-11/12 h-48 sm:h-96 lg:h-64 xl:h-96 object-cover" />
                        <div className=" px-4 w-11/12 sm:h-72">
                        <p className="font-montserrat font-bold text-3xl sm:text-5xl pt-10">{diet.node.title}</p>
                        <p className="font-opensans text-xs mt-1">{diet.node.date}</p>
                        <p className="font-opensans font-thin text-sm  mt-1 ">{diet.node.ingredients}<span className="text-sm"> ingredients</span> | {diet.node.duration} <span className="text-xs">Min.</span> | {diet.node.calories} <span className="text-xs">Approx.Cal.</span></p>
                        <p className="mt-2 capitalize ">Category: {diet.node.category}</p>
                        </div>
                    </Link>
                  </div>
              ))}
            </div>
            </div> */}
            <div
              style={{ backgroundImage: `url(${BG})`, backgroundSize: "cover" }}
              className="mt-20 pt-32 pb-40"
            >
              <Container>
                <div className=" text-center">
                  <h1 className="text-white font-montserrat font-black text-5xl">
                    What's on Your Plate ?
                  </h1>
                  <p className="font-opensans text-white mb-10">
                    Experience the joy of going back to eden. Start your diet
                    reform now with delicious options. Take your pick !
                  </p>
                </div>
                <Row>
                  {data.allStrapiRecipes.edges.map(diet => (
                    <Col
                      key={diet.node.id}
                      sm={6}
                      md={6}
                      lg={4}
                      xl={3}
                      className="pb-4"
                    >
                      <Link
                        to={`/diet/${diet.node.category}/${diet.node.slug}`}
                      >
                        <div className="flex flex-col justify-center items-center text-white">
                          <Image
                            srcSet={diet.node.imgix_path}
                            src={diet.node.src_img}
                            alt="Food"
                            className="object-cover rounded-full h-56 w-56 md:h-64 md:w-64 p-3 bg-white border-gray-500 border"
                          />
                          <p className="m-0 mt-4 font-bold font-montserrat">
                            {diet.node.title}
                          </p>
                          <p className="font-opensans font-thin text-sm  mt-1 sm:hidden md:block">
                            {diet.node.ingredients}
                            <span className="text-sm"> ingredients</span> |{" "}
                            {diet.node.duration}{" "}
                            <span className="text-xs">Min.</span> |{" "}
                            {diet.node.calories}{" "}
                            <span className="text-xs">Approx.Cal.</span>
                          </p>
                          <p className="m-0 font-opensans text-sm">
                            Suggested for {diet.node.category}
                          </p>
                        </div>
                      </Link>
                    </Col>
                  ))}
                </Row>
              </Container>
            </div>
          </React.Fragment>
        )}
      />
    </div>
  )
}
export default Diet
