import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import { Container, Image, Row, Col, Button} from 'react-bootstrap'


const WorldToday = () => {

    return (
        <div>
        <StaticQuery
        query={graphql`
          query WorldToday {
            allStrapiWorldtodays(limit: 8, sort: {fields: date, order: DESC}) {
                edges {
                node {
                    title
                    slug
                    date(formatString: "LL")
                    h_desc
                    b_desc
                    image{
                      url
                    }
                }
                }
            }
          }
        `}
        render={data => (
            <React.Fragment>
            <Container className="py-32">
            <div className="flex items-center text-gray-700">
                <i class="fas fa-globe fa-lg pr-2"></i>
                <p className="m-0 text-sm">Global Report</p>
            </div>
            <div className="sm:flex flex-row justify-between items-center pb-4">
                <h1 className="text-gray-700">Breaking News</h1>
                <Link to="/worldtoday">
                <Button>All Reports</Button>
                </Link>
            </div>
            <Row>
           
             {data.allStrapiWorldtodays.edges.map(report =>(
                 <Col key={report.node.id} className="text-gray-700 " sm={6} lg={4} xl={3}>
                 <Link to={`/worldtoday/${report.node.slug}`}>
                    <Image src={report.node.image.url} alt="World Today" className="w-full h-48 object-cover"/>
                      <p className="m-0 pb-2 font-opensans text-sm text-gray-500 pt-2">{report.node.date}</p>
                      <p className="font-opensans font-black text-gray-700">{report.node.title}</p>
                      </Link>
                 </Col>
             ))}
          
            </Row>
            </Container>
            </React.Fragment>
          )}
         />
     </div>
    )
}
export default WorldToday
