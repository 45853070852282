import React from 'react';
import { Link } from "gatsby"
import C1 from "../images/c1.jpg"
import { Container} from 'react-bootstrap'


const Country = () => {
    return(
        <div  className="sm:w-9/10 sm:ml-1/20 py-20 xs:px-4 sm:px-0">

        <Container>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
            <div style={{backgroundImage: `url(${C1})`, backgroundSize: "cover"}} className="w-full xs:h-96 sm:h-108 lg:h-112"><p className="font-playfairdisplay font-bold xs:text-7xl sm:text-9xl lg:text-8xl xl:text-9xl text-white xs:pt-32 sm:pt-56 lg:pt-64 text-center">Country Living ?</p></div>
                <div className="font-playfairdisplay font-bold text-gray-700 xs:mx-4 sm:mx-6">
                    <p className=" xs:text-6xl sm:text-7xl xl:text-8xl   ">Have what it takes ?</p>
                    <p className="text-5xl mt-10 text-orange-dark">Afraid or Uncertain ?</p>

                    <div className=" bg-blue-dark text-white mt-10  px-4 pb-4 pt-4 2xl:pt-16">
                    <p className="text-lg mt-10">Come Join Us on a Monday Evening at 8:30 PM check out our links above to find out more...</p>
                    <p className="pt-4"> 
                    <Link to="/lifestyles/country">
                    <span className="text-orange-light cursor-pointer">For Country Living </span>  
                    </Link> information, be sure to click this link</p>
                    </div>
                    <div className="bg-orange-dark text-white mt-2 px-2 py-2">
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSeOekS-HSu4IWwgn0boRPxxsrLvO7VEMjdDKYn7E3ZEs-jl-w/viewform?vc=0&c=0&w=1&flr=0&usp=mail_form_link"
                           rel="external nofollow noopener noreferrer"
                           target="_blank">
                          <p>Also, you can click here for our skills bank online form</p>
                        </a>
                    </div>
                    
                </div>
            </div>
        </Container>

        </div>
    )
}
export default Country