import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
// import ReactMarkdown from "react-markdown"
import { Container, Row, Col, Image} from 'react-bootstrap'
// import HERO from "../images/Hero.jpg"
import DIVI from "../images/Divider.png"

const Broadcast = () => {

    return (
        <div>
        <StaticQuery
        query={graphql`
          query Events {
            allStrapiEvents(sort: {fields: date, order: ASC}, limit: 4) {
                edges {
                    node {
                        date(formatString: "LL")
                        starttime
                        desc
                        meetingid
                        passcode
                        id
                        link
                        platform
                        title
                        day
                        month
                         image {
                               url
                        }
                    }
                }
            }
          }
        `}
        render={data => (
            <React.Fragment>
                {/* <div  className="sm:w-9/10 sm:ml-1/20 pb-20 xs:px-4 sm:px-0">
                    <h1 className="font-playfairdisplay font-bold text-5xl text-blue-dark xs:text-center sm:text-left mb-6 pt-20">Upcoming Events</h1>
                    <section>
                         <div className="grid xs:grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2  xs:px-2 sm:px-0">
                          {data.allStrapiEvents.edges.map(events =>(
                            <Link to={events.node.link}>
                            <div key={events.node.id} className="xs:text-gray-700 sm:text-white xs:mt-4 xs:border-b border-blue-alt sm:mt-0 sm:bg-blue-dark sm:border-0 xs:h-64 sm:h-108 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 xl:text-gray-300 xl:hover:text-white xl:hover:shadow-xl">
                                <img src={events.node.image.url} alt="events" className="w-full h-72 2xl:h-80 object-cover mt-4 xs:hidden sm:block" />
                                <div className="p-2 xs:text-center sm:text-left">
                                <p className="font-montserrat text-sm lg:text-base h-14">{events.node.title}</p>
                                <p className="font-opensans font-bold sm:text-xl lg:text-2xl xl:text-xl 2xl:text-2xl h-16">{events.node.desc}</p>
                                <p className="text-opensans text-xs mt-2">{events.node.date} - {events.node.starttime}</p>
                                <p className="font-opensans font-bold text-xs mt-4">{events.node.platform}</p>
                                <p className="font-opensans text-sm mt-2">Meeting ID: {events.node.meetingid}, Passcode: {events.node.passcode}</p>
                                </div>
                            </div>
                        </Link>
                            ))}
                        </div>
                    </section>
                    </div> */}
                    <Container className="mb-10">
                    <div className="mt-10">
                        <div className="flex items-center justify-center text-orange-dark">
                        <i className="fas fa-video fa-lg pr-2"></i> 
                        <p className="m-0">Online Meetings via Zoom</p>
                        </div>
                        <div>
                            <h1 className="text-center mt-2 text-gray-700">Our Upcoming Meetings</h1>
                        </div>
                        <div className="flex justify-center mt-5">
                           <Image src={DIVI} alt="Divider" className="w-48 text-red-500"/>
                        </div>
                    </div>
                    <Row className="mt-5">
                   
                    {data.allStrapiEvents.edges.map(events =>(
                       
                        <Col key={events.node.id} sm={6} lg={4} xl={3} className="pb-2">
                        <Link to={events.node.link}>
                        <div className="border-1 border-gray-300 rounded-lg p-2 h-100">
                        <div className=" bg-white text-orange-alt absolute z-10 ml-1/10 p-2 h-20 w-20 flex flex-col justify-center items-center rounded-b-md">
                            <p className="m-0 font-opensans font-black text-3xl">{events.node.day}</p>
                            <p className="m-0 font-opensans  text-xl">{events.node.month}</p>
                        </div>
                            <div>
                                <Image src={events.node.image.url} alt="community" className="rounded-t-md h-48 w-full object-cover"/>
                            </div>
                            <div>
                                <div>
                                    <p className=" font-opensans font-bold text-sm">{events.node.starttime}</p>
                                </div>
                                <div>
                                    <h5 className="font-playfairdisplay font-bold">{events.node.title}</h5>
                                </div>
                                <div>
                                    <p className="font-opensans text-sm">{events.node.desc}</p>
                                </div>
                                <div>
                                    <p className="font-opensans text-sm">M.ID - {events.node.meetingid} , P.Code - {events.node.passcode}</p>
                                </div>
                            </div>
                            </div>
                            </Link>
                        </Col>
                       
                        ))}
                       
                        </Row>
                    </Container>

            </React.Fragment>
          )}
         />
     </div>
    )
}
export default Broadcast
