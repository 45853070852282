import React from "react"
import { Helmet } from "react-helmet"
import {  graphql } from "gatsby"
import Layout from "../components/Layout.js"
import Broadcast from "../components/Broadcast.js"
import BibleStudy from "../components/Word.js"
import Diet from "../components/Diet.js"
import Healthfitness from "../components/Health_Fitness.js"
import Worldtoday from "../components/WorldToday.js"
// import MORNING from "../images/morning.jpg"
import Social from "../components/social.js"
import Country from "../components/Country.js"


const Home = ({ data }) =>  (
  <Layout>

  <Helmet>
    <meta charSet="utf-8" />
    <meta name="description" content="Are you ready to meet Jesus Christ. He is coming soon?" />
    <title>ReaCH | Home</title>
    <link rel="canonical" href="https://reachun.org/" />
  </Helmet>
        
         {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 py-24 sm:py-40 xs:px-4 sm:px-20 bg-orange-dark"> 
        <div style={{backgroundImage: `url(${MORNING})`, backgroundSize: "cover"}} className="h-108 lg:h-120">
            <h1 className="font-playfairdisplay font-bold text-6xl sm:text-7xl md:text-8xl 2xl:text-9xl mt-32 2xl:mt-48 mx-10 text-center text-white mb-4">A Great Way To Start Your Day</h1>
        </div>
        {data.allBuzzsproutPodcastEpisode.edges.map(podcast =>(
            <div key={podcast.node.id} className="">
                <p className="font-playfairdisplay text-white text-3xl xl:text-4xl 3xl:text-5xl leading-tight">How you Start your day can determine the outcome. Heavenly themes strength our mind and nourish the soul.</p>
                <p className="font-opensans text-white text-lg xl:text-xl leading-normal mt-2"> Why not start your day with God, allow your mind to bask in his prescence.
                You may be late going to work or in traffic on the roadway, that's not a problem you can listen to our morning devotion as you go on your way. Have a blessed day in Jesus.</p>
                <div className="border-b-4 border-white mx-16 sm:mx-32 xl:mx-48 2xl:mx-64 my-5 2xl:my-10 3xl:my-6"></div>
                <p className="font-opensans text-white">{podcast.node.published_at} - {podcast.node.artist}</p>
                <p className="font-opensans font-bold text-lg xl:text-xl text-white mt-4">{podcast.node.summary}</p>

                <div id="buzzsprout-player-9394302" className="mt-10"></div>

                <Link to="/devotions">
                <div className="flex mt-20 items-center space-x-8">
                    <p className="font-opensans text-white text-sm">Click the Link to listen to missed</p>
                    <section className="">
                        <Link to="/devotions">
                          <button className="font-opensans font-bold text-sm text-white bg-orange-dark ring ring-orange-light ring-offset-2 rounded-sm py-1 px-4">
                          Morning Devotions
                          </button>
                          </Link>
                    </section>
                </div>
                </Link>
          </div>
        ))}
         </div>  */}
        <div className=""> 
         <Broadcast />
         </div>
         <BibleStudy />
         <div className="">
           <Country />
         </div>
         <div className="bg-gradient-to-t from-red-600 to-red-200"> 
         <Diet />
         </div>
         <Healthfitness />
         <div className=""> 
         <Worldtoday />
        </div>
         <Social />
    </Layout>   
)
export const pageQuery = graphql`
query BuzzsproutQuery {
  allBuzzsproutPodcastEpisode(limit: 1, sort: {order: DESC, fields: published_at}) {
      edges {
      node {
          artist
          artwork_url
          audio_url
          description
          duration
          summary
          title
          total_plays
          published_at(formatString: "LL")
          id
      }
    }

  }
    
  }
` 

 
    
export default Home