import React from "react"
import NewHeader from "./NewHeader.js"
import Footer from "./Footer.js"

//import 'bootstrap/dist/css/bootstrap.min.css';
import  "../styles/global.scss"
import  "../styles/select-css.css"
import { pageBody, content, base } from "./layout.module.scss"

const Layout = props => {
  return (
    <div className={pageBody}>
      <div key="1" className={content}>
        <NewHeader />
        <div>{props.children}</div>
      </div>
      <div key="2" className={base}>
        <Footer />
      </div>
    </div>
  )
}

export default Layout